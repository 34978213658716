import React from "react";
import style from './Loader.module.css';

function Loader() {
  return (
    <>
      <div id="loaderspin">
        <span className={style.loading}></span>
      </div>
    </>
  )
}

export default Loader