import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import swipeStyle from './AddNumber.module.css';
import Constants from '../../core/Constants';
import { updateLoaderStatus } from '../../core/redux/actions';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveNumber } from '../../services/api.services'

export const AddNumber = () => {
  const { profile_code, to_number } = useParams();
  const [mobile, setmobile] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const lastScanProfileCode = useSelector((state) => state.lastScanProfileCode);
  const navigate = useNavigate();
  const makeCall = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await saveNumber({ from_number: `91${mobile}`, profile_code, to_number: to_number });
    if (result.data.status === true) {
      Constants.setUnKnownNumber(`91${mobile}`)
      window.location.replace(`tel:${process.env.REACT_APP_BRIGE_NUMBER}`)
      $('.shareDetailsOverlay').css('display', 'none');
      navigate(`/ea/${profile_code}`)
    }
    dispatch(updateLoaderStatus(false));
  }
  useEffect(() => {
    $('.shareDetailsOverlay').css('display', 'block');
    if (mobile.length === 10) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [mobile]);
  return (
    <>
      <div className='shareDetailsOverlay' style={{ maxWidth: '414px', margin: '0 auto' }}>
        <div className='dvLoginDetails'>
          <div className='dvLoginDetailsContent' style={{ maxWidth: '390px', margin: '0 auto', overflow: 'hidden' }}>
            <div className='btnHolder' style={{ marginBottom: '10%' }}>
              <button className={`${swipeStyle['btnClose']}`} id="closeSlider"
                onClick={() => {
                  $('#loginOverlayTwo, .dvMessage').fadeOut();
                  $(`#${swipeStyle['slider']}`).animate({ left: '-10px' }, 300);
                  $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
                  $('body').css('overflow', 'auto');
                  if (lastScanProfileCode && lastScanProfileCode !== true) {
                    navigate('/ea/' + lastScanProfileCode);
                  } else {
                    navigate(-1);
                  }
                }}><span>
                  CLOSE </span>&#10005;</button>
            </div>
            {/* <a href="tel:6177787312"></a>  */}
            <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/user.png`} alt="" className="imgUser" />
            <h2 style={{ fontWeight: '500', fontSize: '30px' }}>Connect</h2>
            <span>to my <b>Emergency Contact</b>.</span>
            <span className="orgBrd50"></span>
            <div className="dvLoginForm">
              <span style={{ fontSize: "medium" }}><b>Enter your Mobile Number</b></span>
              <div className="form-group" style={{ margin: '6px auto' }}>
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/mobile.png`} alt="" className="icon" />
                  </div>
                  <div className="vrOrg" style={{ "paddingLeft": '8px', 'paddingRight': '5px' }}> +91 </div>
                  <input type="text" className="form-control" id="mobileInput" minLength="10" maxLength="10" onKeyPress={(e) => { Constants.checkPhoneKey(e) }}
                    placeholder="Enter Your Mobile Number" onChange={(e) => setmobile(e.target.value)} />
                </div>

              </div>
              <button className={`nameHeading ${showConfirm ? '' : 'hide'}`}
                onClick={() => makeCall()} >Confirm</button>
              <span className={`error ${swipeStyle['error']}`}>
                {/* {sos_error} */}
              </span>
            </div>
          </div>
        </div>
      </div>



    </>

  )
}
