import React, { useEffect } from "react";
import swipeStyle from './Swipe.module.css';
import headerStyle from '../../header/Header.module.css';
import $ from 'jquery';

function Swipe() {
  useEffect(() => {
    var initialMouse = 0;
    var slideMovementTotal = 0;
    var mouseIsDown = false;
    var slider = $(`#${swipeStyle['slider']}`);

    slider.on('mousedown touchstart', function (event) {
      mouseIsDown = true;
      slideMovementTotal = $(`#${swipeStyle['button-background']}`).width() - 85; //$(this).width();
      initialMouse = event.clientX || event.originalEvent.touches[0].pageX;
    });

    $(document.body, `#${swipeStyle['slider']}`).on('mouseup touchend', function (event) {
      if (!mouseIsDown)
        return;
      mouseIsDown = false;
      var currentMouse = event.clientX || event.changedTouches[0].pageX;
      var relativeMouse = currentMouse - initialMouse;

      // check partial slide
      if (relativeMouse < slideMovementTotal) {
        $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
        slider.animate({
          left: "-10px"
        }, 300);
        return;
      }
      // full slide
      $('#sos_call')[0].click();
      // window.location = $("#sos_call").attr('href');
      // window.open($("#sos_call").attr('href'), '_blank');
      // document.location.href="tel:"+$("#sos_call").attr('href');
      setTimeout(() => {
        $('#closeSlider')[0].click();
      }, 1000);

      return;
      $('.dvShareDetailsContent1').css('left', '0');
      $('#overlayTwo, .dvShareDetails, .dvShareDetailsContent, .dvShareDetailsContent1').fadeIn(300);
      $('.dvShareOTP').fadeOut(0);
      $('body').css('overflow', 'hidden');
      // slider.addClass('unlocked');
      $('#locker').text('lock_outline');
      setTimeout(function () {
        slider.on('click tap', function (event) {
          if (!slider.hasClass(`${swipeStyle['unlocked']}`))
            return;
          slider.removeClass(`${swipeStyle['unlocked']}`);
          $('#locker').text('lock_open');
          slider.off('click tap');
        });
      }, 0);
    });

    $(document.body).on('mousemove touchmove', function (event) {
      if (!mouseIsDown)
        return;
      var currentMouse = event.clientX || event.originalEvent.touches[0].pageX;
      var relativeMouse = currentMouse - initialMouse;
      var slidePercent = 1 - (relativeMouse / slideMovementTotal);
      $(`.${swipeStyle['slide-text']}`).fadeTo(0, slidePercent);
      if (relativeMouse <= 0) {
        slider.css({ 'left': '20px' });
        return;
      }
      if (relativeMouse >= slideMovementTotal + 10) {
        slider.css({ 'left': slideMovementTotal + 'px' });
        return;
      }
      slider.css({ 'left': relativeMouse - 10 });
    });
  })
  return (
    <>
      <div className={`${swipeStyle['dvEmergency']} ${headerStyle['dvEmergency']}`}>
        <h2 className={`${swipeStyle['emergencyHeading']}`}>
          In case of&nbsp;
          <strong>Emergency</strong>
        </h2>
        <h4 className={`${swipeStyle['emergencyDesc']}`}>
          <strong>Swipe and Connect</strong>&nbsp;
          to Emergency Contact
        </h4>
      </div>
      <div className={`${swipeStyle['dvSwipe']} ${headerStyle['dvSwipe']}`}>
        <div id={`${swipeStyle['button-background']}`}>
          <span className={`${swipeStyle['slide-text']}`}>SWIPE RIGHT</span>
          <div id={`${swipeStyle['slider']}`}>SOS</div>
        </div>
      </div>
    </>
  )
}

export default Swipe;