import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScannedProfile from "../../pages/scanned_profile/ScannedProfile";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Loading from "../components/loading/Loading";
import Profile from "../../pages/profile/Profile";
import SosProfile from "../../pages/storeQr/sos_profile/SosProfile";
import Home from "../../pages/Home/Home";
import EditProfile from "../../pages/edit_profile/EditProfile";
import Scan from '../../pages/templates/default/scan/Scan';
import Login from "../../pages/login/Login";
import ProfilePage from "../../pages/storeQr/sos_profile/ProfilePage";
import { AddNumber } from "../../pages/add_number/AddNumber";
import TokenNotFound from "../../pages/storeQr/sos_profile/TokenNotFound";
import SuccessPage from "../../pages/storeQr/sos_profile/SuccessPage";
import UpdateSuccess from "../../pages/storeQr/sos_profile/UpdateSuccess";
function MobileView() {
  const location = useLocation();
  useEffect(() => {
  }, [location])
  return (
    <div className="mainHolder">
      <Loading />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register/:token_code" element={<SosProfile />} />
        <Route path="/registered-success" element={<SuccessPage />} />
        <Route path="/updated-success" element={<UpdateSuccess />} />
        <Route path="/token_not_found" element={<TokenNotFound />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="edit_profile/:type" element={<EditProfile />} />
        <Route path="ea/:profile_web_link" element={<ScannedProfile />} />
        <Route path="/logout" element={<Scan />} />
        <Route path='/addnumber/:profile_code/:to_number' element={<AddNumber />} />
        <Route path='/addnumber/:profile_code' element={<AddNumber />} />
      </Routes>
      <Sidebar />
    </div>
  );
}

export default MobileView;
