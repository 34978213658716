import React, { useEffect } from "react";
import "./App.css";
import { useCookies } from 'react-cookie';
import MobileView from "./layout/mobile/MobileView";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken, updateLastScanProfileCode, updateLoaderStatus } from "./core/redux/actions";
import $ from 'jquery';

function App() {
  // const [cookies] = useCookies(['user']);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const lastScanProfileCode = useSelector((state) => state.lastScanProfileCode);
  useEffect(() => {
    dispatch(updateLoaderStatus(true));
    if (localStorage.getItem('Token')) {
      dispatch(setToken(localStorage.getItem('Token')));
    } else {
      dispatch(setToken(''));
    }
  }, [localStorage.getItem('Token')]);

  useEffect(() => {
    dispatch(setToken(localStorage.getItem('Token')))
  }, [localStorage])

  useEffect(() => {
    if (token === '') {
      document.cookie = "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    dispatch(updateLoaderStatus(false));
    if (!pathname.includes('/ea/', 0) && !pathname.includes('/logout', 0)) {
      if (token !== null) {
        if (token === '') {
          if (['/'].indexOf(pathname) > -1) {
            navigate(pathname, { replace: true });
          } else {
            navigate('/', { replace: true });
            $('.btnLogin').click()
          }
        }
        else if (token !== '') {
          if (pathname === '/login') {
            if (lastScanProfileCode && lastScanProfileCode != true) {
              navigate('/ea/' + lastScanProfileCode, { replace: true });
            } else {
              navigate('/profile', { replace: true });
            }
          } else {
            navigate(pathname, { replace: true });
          }
        }
      }
    }
  }, [pathname, token]);

  // useEffect(()=>{
  //   dispatch(updateLastScanProfileCode(null))
  // },[]);
  return (
    <>
      <div className="App">
        <MobileView />
      </div>
    </>

  );
}

export default App;
