import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateCode, updateLoaderStatus } from "../../core/redux/actions";
import DefaultProfile from "../templates/default/profile/Profile";
import { useLocation, useNavigate } from "react-router-dom";
import CorporateDefaultProfile from "../templates/corporate_default/profile/Profile";
import CorporateDefaultQRProfile from "../templates/default_corporate_qr/profile/Profile";
import { getProfile } from "../../services/api.services";
import Loader from "../../layout/loader/Loader";
import CorporateCallMaskingProfile from "../templates/corporate_call_masking/profile/Profile";
import IndividualCallMaskingProfile from '../templates/Individual_call_masking/profile/Profile'
function Profile() {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});
  const templateCode = useSelector((state) => state.templateCode);
  const navigate = useNavigate();
  const [_templateCode, _setTemplateCode] = useState('');
  const [isLoading, setisLoading] = useState(true);
  const lastScanProfileCode = useSelector((state) => state.lastScanProfileCode);
  const history = useNavigate()

  useEffect(() => {
    if (!token) {
      navigate(`/ea/${localStorage.getItem('profile_code')}`, { replace: true });
    }
    (async () => {
      dispatch(updateLoaderStatus(true));
      try {
        let result = await getProfile();
        if (result.data.status) {
          setProfile(result.data.data);
          dispatch(setTemplateCode(result.data.data.template_code || result.data.data.template_type));
          _setTemplateCode(result.data.data.template_code || result.data.data.template_type);
        }
      }
      catch (err) {
        console.log(err);
      }
      finally {
        setisLoading(false);
      }
      dispatch(updateLoaderStatus(false));
    })();
  }, []);

  const getTemplate = useCallback(() => {
    switch (_templateCode) {
      case 'asian_paints':
        return <></>
      case 'corporate_default':
        return <CorporateDefaultProfile profile={profile}></CorporateDefaultProfile>
      case 'abc_comp':
        return <CorporateDefaultQRProfile profile={profile}></CorporateDefaultQRProfile>
      case 'corporate_call_masking':
        return <CorporateCallMaskingProfile profile={profile}></CorporateCallMaskingProfile>
      case 'individual_call_masking':
        return <IndividualCallMaskingProfile profile={profile}></IndividualCallMaskingProfile>
      default:
        return <DefaultProfile profile={profile}></DefaultProfile>
    }
  }, [_templateCode]);

  return (
    <>
      {isLoading ?
        <Loader /> : getTemplate(templateCode)}

    </>
  )
}

export default Profile;