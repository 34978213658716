import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { submitProfileData, getTokenInfo } from '../../../services/api.services';

const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [fileData, setFileData] = useState(null);
    const navigate = useNavigate();
    const { token_code } = useParams();
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        step1: { first_name: '', middle_name: '', last_name: '', mobile: '', email: '', address: '', city: '', state: '', country: '', zipcode: '', landmark: '' },
        step2: { primaryContactName: '', primaryContactMobile: '', secondaryContactName: '', secondaryContactMobile: '', policy_relationship_manager_name: '', policy_relationship_manager_mobile: '', policy_tollfree_number: '' },
        step3: { blood_group: '', allergies: '', medical_condition: '', primary_physician_name: '', primary_physician_mobile: '' },
        step4: { medicalaim_policy_personal_company: '', medicalaim_policy_personal_id: '', medicalaim_policy_personal_expiry: '', medicalaim_policy_personal: null, filePreview: null }
    });

    const getDetails = async () => {
        try {
            let result = await getTokenInfo(token_code);
            console.log('Full response:', result); // Log the entire response

            if (result.data && result.data.status) {
                // Update formData with the retrieved values
                setFormData(prevFormData => ({
                    ...prevFormData,
                    step1: {
                        ...prevFormData.step1,
                        first_name: result.data.data.contact_name || '',
                        last_name: result.data.data.contact_lastname || '',
                        mobile: result.data.data.contact_phone || '',
                        email: result.data.data.contact_email || '',
                    }
                }));
            } else {
                console.error('Invalid response structure or status is false');
            }
        } catch (error) {
            console.error('Error fetching token info:', error);
        }
    };

    // Use useEffect to trigger the data fetch when the component mounts
    useEffect(() => {
        getDetails();
    }, []);

    const validateStep = (stepData) => {
        const stepErrors = {};
        if (currentStep === 0) {
            if (!stepData.first_name) stepErrors.first_name = 'First name is required';
            if (!stepData.last_name) stepErrors.last_name = 'Last name is required';
            if (!stepData.mobile) {
                stepErrors.mobile = 'Mobile number is required';
            } else if (!/^\d{10}$/.test(stepData.mobile)) {
                stepErrors.mobile = 'Mobile number must be 10 digits';
            }
            if (!stepData.email) {
                stepErrors.email = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(stepData.email)) {
                stepErrors.email = 'Email is invalid';
            }
            // if (!stepData.address) stepErrors.address = 'Address is required';
            // if (!stepData.city) stepErrors.city = 'City is required';
            // if (!stepData.state) stepErrors.state = 'State is required';
            // if (!stepData.landmark) stepErrors.landmark = 'Landmark is required';
            else if (stepData.zipcode && !/^\d{6}$/.test(stepData.zipcode)) {
                stepErrors.zipcode = 'Pincode number must be 6 digits';
            }
        } else if (currentStep === 1) {
            if (!stepData.primaryContactName && stepData.primaryContactMobile) stepErrors.primaryContactName = 'Primary Contact Name is required';
            if (stepData.primaryContactName && !stepData.primaryContactMobile) stepErrors.primaryContactMobile = 'Primary Contact Mobile is required';

            if (!stepData.secondaryContactName && stepData.secondaryContactMobile) stepErrors.secondaryContactName = 'Secondary Contact Name is required';
            if (stepData.secondaryContactName && !stepData.secondaryContactMobile) stepErrors.secondaryContactMobile = 'Secondary Contact Mobile is required';
            // if (!stepData.primaryContactMobile) {
            //     stepErrors.primaryContactMobile = 'Primary Contact Mobile is required';
            // } else if (!/^\d{10}$/.test(stepData.primaryContactMobile)) {
            //     stepErrors.primaryContactMobile = 'Primary Contact Mobile must be 10 digits';
            // }
            // if (!stepData.secondaryContactName) stepErrors.secondaryContactName = 'Secondary Contact Name is required';
            // if (!stepData.secondaryContactMobile) {
            //     stepErrors.secondaryContactMobile = 'Secondary Contact Mobile is required';
            // } else if (!/^\d{10}$/.test(stepData.secondaryContactMobile)) {
            //     stepErrors.secondaryContactMobile = 'Secondary Contact Mobile must be 10 digits';
            // }
            if (!stepData.policy_relationship_manager_name && stepData.policy_relationship_manager_mobile) stepErrors.policy_relationship_manager_name = 'Policy Relationship Manager Name is required';
            if (stepData.policy_relationship_manager_name && !stepData.policy_relationship_manager_mobile) stepErrors.policy_relationship_manager_mobile = 'Policy Relationship Manager Mobile is required';
            // if (!stepData.policy_relationship_manager_mobile) {
            //     stepErrors.policy_relationship_manager_mobile = 'Policy Relationship Manager Mobile is required';
            // } else if (!/^\d{10}$/.test(stepData.policy_relationship_manager_mobile)) {
            //     stepErrors.policy_relationship_manager_mobile = 'Policy Relationship Manager Mobile must be 10 digits';
            // }
            // if (!stepData.policy_tollfree_number) {
            //     stepErrors.policy_tollfree_number = 'Toll-Free Number is required';
            // } else if (!/^\d{10}$/.test(stepData.policy_tollfree_number)) {
            //     stepErrors.policy_tollfree_number = 'Toll-Free Number must be 10 digits';
            // }
        } else if (currentStep === 2) {
            // if (!stepData.blood_group) stepErrors.blood_group = 'Blood Group is required';
            // if (!stepData.allergies) stepErrors.allergies = 'Allergies are required';
            // if (!stepData.medical_condition) stepErrors.medical_condition = 'Medical Condition is required';
            if (!stepData.primary_physician_name && stepData.primary_physician_mobile) stepErrors.primary_physician_name = 'Primary Physician Name is required';
            if (stepData.primary_physician_name && !stepData.primary_physician_mobile) stepErrors.primary_physician_mobile = 'Primary Physician Mobile is required';
            // if (!stepData.primary_physician_mobile) {
            //     stepErrors.primary_physician_mobile = 'Primary Physician Mobile is required';
            // } else if (!/^\d{10}$/.test(stepData.primary_physician_mobile)) {
            //     stepErrors.primary_physician_mobile = 'Primary Physician Mobile must be 10 digits';
            // }
        } else if (currentStep === 3) {
            // if (!stepData.medicalaim_policy_personal_company) stepErrors.medicalaim_policy_personal_company = 'Company Name is required';
            // if (stepData.medicalaim_policy_personal_company && stepData.medicalaim_policy_personal_company.length < 3) stepErrors.medicalaim_policy_personal_company = 'Company Name must be at least 3 characters';
            // else if (stepData.medicalaim_policy_personal_company && !stepData.medicalaim_policy_personal_id && !stepData.medicalaim_policy_personal) stepErrors.medicalaim_policy_personal_id = 'Policy ID is required';

            if (!stepData.medicalaim_policy_personal_company && stepData.medicalaim_policy_personal_id) stepErrors.medicalaim_policy_personal_company = 'Company Name is required';
            if (stepData.medicalaim_policy_personal_company && !stepData.medicalaim_policy_personal_id) stepErrors.medicalaim_policy_personal_id = 'Policy ID is required';
            // if (stepData.medicalaim_policy_personal_company && stepData.medicalaim_policy_personal_id && !stepData.medicalaim_policy_personal_expiry && stepData.medicalaim_policy_personal) stepErrors.medicalaim_policy_personal_expiry = 'Policy Expiry Date is required';
            // if (!stepData.medicalaim_policy_personal_id) stepErrors.medicalaim_policy_personal_id = 'Policy ID is required';
            // if (stepData.medicalaim_policy_personal_company && stepData.medicalaim_policy_personal_id && !stepData.medicalaim_policy_personal_expiry) stepErrors.medicalaim_policy_personal_expiry = 'Policy Expiry Date is required';
            // if (stepData.medicalaim_policy_personal && !stepData.medicalaim_policy_personal_expiry) stepErrors.medicalaim_policy_personal_expiry = 'Policy Expiry Date is required';
            if (!stepData.medicalaim_policy_personal && stepData.medicalaim_policy_personal_expiry) stepErrors.medicalaim_policy_personal = 'Policy Document is required';
        }
        return stepErrors;
    };

    const handleChange = (step, event) => {
        const { name, value, files } = event.target || {};
        if (
            name === 'mobile' ||
            name === 'primaryContactMobile' ||
            name === 'secondaryContactMobile' ||
            name === 'policy_relationship_manager_mobile' ||
            name === 'primary_physician_mobile'
        ) {
            if (value.length !== 10) {
                setErrors(prev => ({ ...prev, [name]: 'Mobile number must be exactly 10 digits' }));
            } else {
                setErrors(prev => ({ ...prev, [name]: '' }));
            }
        }

        // Validation for Pincode
        if (name === 'zipcode') {
            if (value.length !== 6) {
                setErrors(prev => ({ ...prev, [name]: 'Pincode must be exactly 6 digits' }));
            } else {
                setErrors(prev => ({ ...prev, [name]: '' }));
            }
        }

        if (
            name === 'first_name' ||
            name === 'last_name' ||
            name === 'city' ||
            name === 'state' ||
            name === 'country' ||
            name === 'primaryContactName' ||
            name === 'secondaryContactName' ||
            name === 'policy_relationship_manager_name' ||
            name === 'primary_physician_name'
        ) {
            if (!/^[a-zA-Z\s]+$/.test(value)) {
                setErrors(prev => ({ ...prev, [name]: 'This field must contain alphabets only' }));
            } else {
                setErrors(prev => ({ ...prev, [name]: '' }));
            }
        }

        if (name === 'medicalaim_policy_personal' && files && files[0]) {
            const file = files[0];
            setFileData(file);
            const fileType = file.type.split('/')[1];

            if (fileType === 'pdf' || fileType === 'jpeg' || fileType === 'png' || fileType === 'jpg') {
                const filePreview = URL.createObjectURL(file);
                setFormData((prevData) => ({
                    ...prevData,
                    [step]: {
                        ...prevData[step],
                        medicalaim_policy_personal: file,
                        filePreview,
                        fileType: fileType === 'pdf' ? 'pdf' : 'image'
                    },
                }));
            }
        } else if (name) {
            setFormData((prevData) => ({
                ...prevData,
                [step]: {
                    ...prevData[step],
                    [name]: value,
                },
            }));
        }
    };

    const nextStep = () => {
        const stepErrors = validateStep(formData[`step${currentStep + 1}`]);
        if (Object.keys(stepErrors).length > 0) {
            setErrors(stepErrors);
        } else {
            setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
        }
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const token = token_code;
    const created_on = new Date();
    const nextMonthDate = new Date(created_on.setMonth(created_on.getMonth() + 1));



    const handleSubmit = async () => {
        const stepErrors = validateStep(formData[`step${currentStep + 1}`]);
        if (Object.keys(stepErrors).length > 0) {
            setErrors(stepErrors);
            return;
        }

        let completeFormData = new FormData();
        const { primaryContactName, primaryContactMobile, secondaryContactName, secondaryContactMobile, policy_relationship_manager_name, policy_relationship_manager_mobile } = formData.step2;
        const data = {
            company_code: 'store',
            token: token,
            created_on: new Date(),
            validity_period: nextMonthDate,
            ...formData.step1,
            contacts: JSON.stringify([
                {
                    contact_first_name: primaryContactName,
                    contact_mobile: primaryContactMobile
                },
                {
                    contact_first_name: secondaryContactName,
                    contact_mobile: secondaryContactMobile
                }
            ]),
            policy_relationship_manager_name: formData.step2.policy_relationship_manager_name,
            policy_relationship_manager_mobile: formData.step2.policy_relationship_manager_mobile,
            policy_tollfree_number: formData.step2.policy_tollfree_number,
            primary_physician_name: formData.step3.primary_physician_name,
            primary_physician_mobile: formData.step3.primary_physician_mobile,
            blood_group: formData.step3.blood_group,
            allergies: formData.step3.allergies,
            medical_condition: formData.step3.medical_condition,
            medicalaim_policy_personal_company: formData.step4.medicalaim_policy_personal_company,
            medicalaim_policy_personal_id: formData.step4.medicalaim_policy_personal_id,
            medicalaim_policy_personal_expiry: formData.step4.medicalaim_policy_personal_expiry,

        };

        Object.keys(data).forEach(key => {
            completeFormData.append(key, data[key]);
        });

        if (fileData) {
            completeFormData.append('medicalaim_policy_personal', fileData);
        }

        try {
            let result = await submitProfileData(completeFormData);
            alert('Your details have been updated successfully!');
            // navigate('/ea/' + token_code)
            navigate('/updated-success')

        } catch (error) {
            console.error('Submission error:', error);
        }

    };

    const steps = [
        <Step1 data={formData.step1} handleChange={(e) => handleChange('step1', e)} errors={errors} />,
        <Step2 data={formData.step2} handleChange={(e) => handleChange('step2', e)} errors={errors} />,
        <Step3 data={formData.step3} handleChange={(e) => handleChange('step3', e)} errors={errors} />,
        <Step4 data={formData.step4} handleChange={(e) => handleChange('step4', e)} errors={errors} />,
    ];

    return (
        <div style={{ margin: '20px' }}>
            {steps[currentStep]}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0', padding: '10px' }}>
                {currentStep > 0 && <button style={buttonStyle} onClick={prevStep}>Previous</button>}
                {currentStep < steps.length - 1 && <button style={buttonStyle} onClick={nextStep}>Next</button>}
                {currentStep === steps.length - 1 && <button style={buttonStyle} onClick={handleSubmit}>Submit</button>}
            </div>
        </div>
    );
};

const buttonStyle = {
    backgroundColor: '#ff9e00',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
};

const renderInput = (label, name, value, handleChange, error, disabled = false) => (
    <div style={{ margin: '5px' }}>
        <small style={{ fontSize: '0.8rem' }}>{label}</small>
        <input
            type="text"
            name={name}
            style={{
                width: '100%',
                padding: '10px',
                margin: '5px 0',
                boxSizing: 'border-box',
                borderRadius: '5px',
                border: '0.6px solid #d1d1d1'
            }}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            maxLength={
                name === 'zipcode' ? 6 :
                    (name === 'mobile' ||
                        name === 'primaryContactMobile' ||
                        name === 'secondaryContactMobile' ||
                        name === 'policy_relationship_manager_mobile' ||
                        name === 'primary_physician_mobile' ||
                        name === 'policy_tollfree_number')
                        ? 10
                        : undefined
            }
            onInput={(event) => {
                if (
                    name === 'mobile' ||
                    name === 'primaryContactMobile' ||
                    name === 'secondaryContactMobile' ||
                    name === 'policy_relationship_manager_mobile' ||
                    name === 'primary_physician_mobile' ||
                    name === 'zipcode'
                ) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                } else if (
                    name === 'first_name' ||
                    name === 'last_name' ||
                    name === 'city' ||
                    name === 'state' ||
                    name === 'country' ||
                    name === 'primaryContactName' ||
                    name === 'secondaryContactName' ||
                    name === 'policy_relationship_manager_name' ||
                    name === 'primary_physician_name'
                ) {
                    event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, ''); // Allow only alphabets and spaces
                }
            }}
        />
        {error && <div style={{ color: 'red', fontSize: '0.8rem' }}>{error}</div>}
    </div>
);

const Step1 = ({ data, handleChange, errors }) => (
    <>
        <h3 style={{ textAlign: 'center', marginTop: '30px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Personal Details</h3>
        <div>
            {renderInput('First Name', 'first_name', data.first_name, handleChange, errors.first_name, true)}
            {renderInput('Middle Name', 'middle_name', data.middle_name, handleChange, errors.middle_name)}
            {renderInput('Last Name', 'last_name', data.last_name, handleChange, errors.last_name, true)}
            {renderInput('Mobile Number', 'mobile', data.mobile, handleChange, errors.mobile, true)}
            {renderInput('Email ID', 'email', data.email, handleChange, errors.email, true)}
            {renderInput('Address', 'address', data.address, handleChange, errors.address)}
            {renderInput('City', 'city', data.city, handleChange, errors.city)}
            {renderInput('State', 'state', data.state, handleChange, errors.state)}
            {renderInput('Country', 'country', data.country, handleChange, errors.country)}
            {renderInput('Pincode', 'zipcode', data.zipcode, handleChange, errors.zipcode)}
            {renderInput('Landmark', 'landmark', data.landmark, handleChange, errors.landmark)}
        </div>
    </>
);

const Step2 = ({ data, handleChange, errors }) => (
    <>
        <h3 style={{ textAlign: 'center', marginTop: '30px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Emergency Contact Details</h3>
        <div>
            {renderInput('Primary Contact Name', 'primaryContactName', data.primaryContactName, handleChange, errors.primaryContactName)}
            {renderInput('Primary Contact Mobile', 'primaryContactMobile', data.primaryContactMobile, handleChange, errors.primaryContactMobile)}
            {renderInput('Secondary Contact Name', 'secondaryContactName', data.secondaryContactName, handleChange, errors.secondaryContactName)}
            {renderInput('Secondary Contact Mobile', 'secondaryContactMobile', data.secondaryContactMobile, handleChange, errors.secondaryContactMobile)}
            {renderInput('Policy Relationship Manager Name', 'policy_relationship_manager_name', data.policy_relationship_manager_name, handleChange, errors.policy_relationship_manager_name)}
            {renderInput('Policy Relationship Manager Mobile', 'policy_relationship_manager_mobile', data.policy_relationship_manager_mobile, handleChange, errors.policy_relationship_manager_mobile)}
            {renderInput('Toll-Free Number', 'policy_tollfree_number', data.policy_tollfree_number, handleChange, errors.policy_tollfree_number)}
        </div>
    </>
);

const Step3 = ({ data, handleChange, errors }) => (
    <>
        <h3 style={{ textAlign: 'center', marginTop: '30px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Medical Details</h3>
        <div>
            {renderInput('Blood Group', 'blood_group', data.blood_group, handleChange, errors.blood_group)}
            {renderInput('Allergies', 'allergies', data.allergies, handleChange, errors.allergies)}
            {renderInput('Medical Condition', 'medical_condition', data.medical_condition, handleChange, errors.medical_condition)}
            {renderInput('Primary Physician Name', 'primary_physician_name', data.primary_physician_name, handleChange, errors.primary_physician_name)}
            {renderInput('Primary Physician Mobile', 'primary_physician_mobile', data.primary_physician_mobile, handleChange, errors.primary_physician_mobile)}
        </div>
    </>
);

const Step4 = ({ data, handleChange, errors }) => (
    <>
        <h3 style={{ textAlign: 'center', marginTop: '30px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Mediclaim Policy Details</h3>
        <div>
            {renderInput('Mediclaim Policy Company Name', 'medicalaim_policy_personal_company', data.medicalaim_policy_personal_company, handleChange, errors.medicalaim_policy_personal_company)}
            {renderInput('Mediclaim Policy ID', 'medicalaim_policy_personal_id', data.medicalaim_policy_personal_id, handleChange, errors.medicalaim_policy_personal_id)}
            <div style={{ margin: '5px' }}>
                <small style={{ fontSize: '0.8rem' }}>Mediclaim Policy Expiry Date</small>
                <input
                    type="date"
                    name="medicalaim_policy_personal_expiry"
                    style={{
                        width: '100%',
                        padding: '10px',
                        margin: '5px 0',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        border: 'none'
                    }}
                    value={data.medicalaim_policy_personal_expiry}
                    onChange={handleChange}
                />
                {errors.medicalaim_policy_personal_expiry && <div style={{ color: 'red', fontSize: '0.8rem' }}>{errors.medicalaim_policy_personal_expiry}</div>}
            </div>
            <div style={{ margin: '5px', border: '1px solid #d1d1d1', borderRadius: '5px', padding: '20px' }}>
                <small style={{ fontSize: '0.8rem' }}>Upload Policy </small>
                <input type="file" name="medicalaim_policy_personal" accept=".pdf,.jpg,.jpeg,.png" onChange={handleChange} />

                {/* {data.filePreview && <img src={data.filePreview} alt="ID Card Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />} */}
                {data.filePreview && (
                    data.fileType === 'pdf' ? (
                        <embed
                            src={data.filePreview}
                            type="application/pdf"
                            width="200px"
                            height="200px"
                            style={{ marginTop: '10px' }}
                        />
                    ) : (
                        <img
                            src={data.filePreview}
                            alt="Document Preview"
                            style={{ maxWidth: '200px', marginTop: '10px' }}
                        />
                    )
                )}

                {errors.medicalaim_policy_personal && <div style={{ color: 'red', fontSize: '0.8rem' }}>{errors.medicalaim_policy_personal}</div>}
            </div>
        </div>
    </>
);

export default MultiStepForm;
