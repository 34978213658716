import Constants from '../../../../core/Constants';
import $ from 'jquery';
import Header from '../../../../layout/header/Header';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTemplateCode, setToken } from '../../../../core/redux/actions';
import { useCookies } from 'react-cookie';
import styles from './Scan.module.css';

function Logout() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['user']);
  useEffect(() => {
    dispatch(setTemplateCode('default'));

    setCookie('Token', '', { path: '/logout' });
    localStorage.removeItem('Token');
    dispatch(setToken(''));

  }, [])

  return (
    <>
      <section className="LogoutDetailsOverlay" id="logoutOverlayTwo" style={{ display: 'block' }}>
        <div className="dvLogoutDetails">
          <div className="dvLogoHolder">
            <div className="dvLogo1">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo.png`} alt="" className="imgLogoNew" />
            </div>
            <div className="vr">&nbsp;</div>
            <div className="dvLogo2">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo2.png`} alt="" className="imgLogo2" />
            </div>
          </div>
          <div className="dvLogoutDetailsContent" style={{ maxWidth: '414px', margin: '0 auto' }}>
            <div className="btnHolder" style={{ "marginBottom": '10px' }}>
              <button className="btnClose" id="closeSlider"
                onClick={() => {
                  $('#logoutOverlayTwo, .dvMessage').fadeOut();
                  // $(`#${swipeStyle['slider']}`).animate({ left: '-10px' }, 300);
                  // $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
                  $('body').css('overflow', 'auto');
                }}>
                {/* <span>
                  CLOSE </span>&#10005; */}
              </button>
            </div>
            <div className="dvLogoutDetailsContent1" style={{ "position": 'relative' }}>
              <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                <img style={{
                  maxHeight: '200px', // Adjust height as needed
                  maxWidth: '100%',   // Adjust width to maintain aspect ratio
                  width: 'auto',      // Ensure width adjusts proportionally
                }} src={`${Constants.PRE_ASSETS_PATH}/assets/images/logout-success.png`} alt="" className="imgUser" />
              </div>
              {/*  <div />
               <p style={{ "marginBottom": '35px', "marginTop": '0', "fontSize": 'larger', textAlign: 'center' }}>You have <strong>successfully logged out </strong> from your account.</p>
               <p style={{ "marginBottom": '35px', "marginTop": '45px', "fontSize": 'large', textAlign: 'center' }}>To view your WellbyQR Emergency Profile,<br /> <strong>kindly scan your QR Code.</strong> </p> */}

              <div />
              <p style={{ "marginBottom": '35px', "marginTop": '0', "fontSize": 'larger', textAlign: 'center', padding: '0 25px' }}>You have <strong>successfully logged out </strong> from your account.</p>
              <p style={{ "marginBottom": '35px', "marginTop": '45px', "fontSize": 'large', textAlign: 'center', padding: '0 20px' }}>To view your WellbyQR Emergency Profile,<br /> <strong>kindly scan your QR Code.</strong> </p>

            </div>

          </div>

        </div>

      </section>
    </>
  )
}

export default Logout;