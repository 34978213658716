import React, { useState } from 'react';
import Constants from '../../../core/Constants';
import style from "./SosProfile.module.css";
import { useNavigate } from "react-router-dom";




const TokenNotFound = () => {

    const navigate = useNavigate();

return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px' }}>
          <img src={Constants.PRE_ASSETS_PATH + "/assets/images/landing/desktop_wellbyqr_logo.png"} alt="" width="60%" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center', paddingTop: '50px' }}>
        <h1 style={{color: '#273741', fontSize: '100px'}}>404</h1>
        <h2 style={{color: '#273741'}}>Token Not Found</h2>
        <small style={{color: '#273741', fontSize: '0.88rem',textAlign: 'justify' ,padding: '20px 80px'}}>We are sorry, the token you requested could not be found. Please try again</small>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <img src={Constants.PRE_ASSETS_PATH + "/assets/images/notfound.png"} alt="" width="60%" />

        </div>
        <div style={{ textAlign: 'center', margin: '40px 0 20px 0' }}>
                  <button className={`${style.saveUpdateBtn}`} style={{backgroundColor: '#273741'}}  onClick={() => navigate('/')} type="submit"> Back Home </button>
                </div>
        <div style={{
          position: 'absolute',
          bottom: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'

        }}>
          <img
            src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbylogo/footer_logo.png`}
            alt=""
            style={{
              width: '120px',
              textAlign: 'center',
              marginTop: '10px'
            }}
            className="icon"
          />
          <hr
            className={`${style.footerHr}`}
            style={{
              borderBottom: '.01rem dashed #787878',
              width: '100%',
              margin: '5px'
            }}
          />
          <div style={{
            margin: '0 0 5px 0',
            fontSize: '12px'
          }}>
            {/* <div style={{ color: '#787878' }}>Copyright © 2024. All Rights Reserved.</div> */}
            <span style={{ color: '#787878' }}>
              Powered by:</span>
            <small>Wellby Solutions Pvt. Ltd.</small>
          </div>
        </div>

    </div>
);
}

export default TokenNotFound