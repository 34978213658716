import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { scanProfile } from '../../services/api.services';
import { useDispatch } from 'react-redux';
import { setTemplateCode, updateLastScanProfileCode, updateLoaderStatus } from '../../core/redux/actions';
import DefaultScannedProfile from '../templates/default/ScannedProfile';
import AsianPaintsScannedProfile from '../templates/asian_paints/ScannedProfile';
import CorporateDefaultScannedProfile from '../templates/corporate_default/ScannedProfile';
import CorporateDefaultQRScannedProfile from '../templates/default_corporate_qr/ScannedProfile';
import CorporateCallMaskingScannedProfile from '../templates/corporate_call_masking/ScannedProfile';
import IndividualCallMaskingScannedProfile from '../templates/Individual_call_masking/ScannedProfile';
import InActive from '../in_active_page/InActive';
function ScannedProfile() {

  const { profile_web_link } = useParams();
  const navigate = useNavigate(); // Add this line

  const [profile, setProfile] = useState({});
  const [location, setLocation] = useState({});
  const dispatch = useDispatch();
  localStorage.setItem('profile_code', profile_web_link)
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000)

  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({
      latitude,
      longitude,
    })

  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
    (async () => {
      dispatch(updateLoaderStatus(true));
      dispatch(updateLastScanProfileCode(profile_web_link))
      let result = await scanProfile(profile_web_link);
      if (result.data.status) {
        setProfile(result.data.data);
      } else {
        return (navigate('/register/' + profile_web_link));
      }
      dispatch(updateLoaderStatus(false));
    })();
  }, []);

  function getTemplate(templateCode) {
    dispatch(setTemplateCode(templateCode));
    switch (templateCode) {
      case 'asian_paints':
        return <AsianPaintsScannedProfile profile={profile} location={location}></AsianPaintsScannedProfile>
      case 'corporate_default':
        return <CorporateDefaultScannedProfile profile={profile} location={location}></CorporateDefaultScannedProfile>
      case 'abc_comp':
        return <CorporateDefaultQRScannedProfile profile={profile} location={location}></CorporateDefaultQRScannedProfile>
      case 'corporate_call_masking':
        return <CorporateCallMaskingScannedProfile profile={profile} location={location}></CorporateCallMaskingScannedProfile>
      case 'individual_call_masking':
        return <IndividualCallMaskingScannedProfile profile={profile} location={location}></IndividualCallMaskingScannedProfile>
      default:
        return <DefaultScannedProfile profile={profile} location={location}></DefaultScannedProfile>
    }
  }


  return (
    <>
      {profile && profile.status === 1 ?
        <span>
          {profile && (profile.template_type === 'default' || profile.template_code) ?
            getTemplate(profile.template_code || profile.template_type)
            : null}
        </span>
        :
        <span>{profile && profile.status === 0 ?
          <InActive />
          : null}
        </span>
      }
    </>
  );
}

export default ScannedProfile;
