import axios from 'axios';
import Constants from '../core/Constants';

export function login(mobile,profile_code) {
  return axios.post(Constants.HOST + '/api/sdk/auth/login', {
    mobile: mobile,
    profile_code :profile_code
  }, {
    headers: Constants.APIHeader
  });
}
export async function sendOtp(mobile) {
  return axios.post(Constants.HOST + '/api/sdk/auth/send_otp', {
    mobile: mobile
  }, {
    headers: Constants.APIHeader
  });
}
export function checkToken(token_code) {
  return axios.post(Constants.HOST + '/api/sdk/auth/check_token',
    {
      token_code: token_code
    },
    {
      headers: Constants.APIHeader
    });
}

export function checkMobileExists(mobile) {
  return axios.post(Constants.HOST + '/api/sdk/auth/check_mobile_exists',
    {
      mobile: mobile
    },
    {
      headers: Constants.APIHeader
    });
}
export function getTokenInfo(token_code) {
  return axios.post(Constants.HOST + '/api/sdk/auth/get_token_info',
    {
      token_code: token_code
    },
    {
      headers: Constants.APIHeader
    });
}

export function verifyOTP(otp_id, otp) {
  return axios.post(Constants.HOST + '/api/sdk/auth/verify_otp', {
    otp_id,
    otp
  }, {
    headers: Constants.APIHeader
  });
}

export function verifyStoreOTP(otp_id, otp) {
  return axios.post(Constants.HOST + '/api/sdk/auth/verify_store_otp', {
    otp_id,
    otp
  }, {
    headers: Constants.APIHeader
  });
}

export function getProfile() {
  return axios.get(Constants.HOST + '/api/sdk/auth/profile', {
    headers: Constants.APIHeader
  });
}

export function scanProfile(profile_code) {
  return axios.get(Constants.HOST + '/api/sdk/scan/' + profile_code, {
    headers: Constants.APIHeader
  });
}

export function getProfileData(token_code) {
  alert('from service', token_code)
  return axios.get(Constants.HOST + '/api/sdk/storeqr/' + token_code, {
    headers: Constants.APIHeader
  });
}

export function sos(profile_code, phone, name, location) {
  return axios.post(Constants.HOST + '/api/sdk/scan/sos', {
    profile_code,
    phone,
    name,
    location,
  }, {
    headers: Constants.APIHeader
  });
}

export function signup(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/signup', data, {
    headers: Constants.APIHeader
  });
}

export function updateProfileData(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/profile/update_details', data, {
    headers: Constants.APIHeader
  });
}

export async function submitProfileData(data) {
  return axios.post(Constants.HOST + '/api/sdk/storeqr/saveProfile/', data, {
    headers: Constants.FileAPIHeader
  });
}

export function updateContactData(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/profile/update_contacts', data, {
    headers: Constants.APIHeader
  });
}

export function updateMedicalData(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/profile/update_medical', data, {
    headers: Constants.FileAPIHeader,
  })
}
export function tokenRegister(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/token_register', data, {
    headers: Constants.FileAPIHeader,
  })
}
export function saveNumber(data) {
  return axios.post(Constants.HOST + '/api/sdk/auth/save_number', data, {
    headers: Constants.APIHeader,
  })
}
